<template>
    <div class="bg-white overflow-hidden">
        <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div
                class="
                    hidden
                    lg:block
                    bg-red-50
                    absolute
                    top-0
                    bottom-0
                    left-3/4
                    w-screen
                "
            />
            <div
                class="
                    mx-auto
                    text-base
                    max-w-prose
                    lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none
                "
            >
                <div>
                    <h2
                        class="
                            text-base text-red-600
                            font-semibold
                            tracking-wide
                            uppercase
                        "
                    >
                        Qui suis-je
                    </h2>
                    <h3
                        class="
                            mt-2
                            text-3xl
                            leading-8
                            font-extrabold
                            tracking-tight
                            text-red-900
                            sm:text-4xl
                        "
                    >
                        Annick Rhéaume Watters
                    </h3>
                </div>
            </div>
            <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                <div class="relative lg:row-start-1 lg:col-start-2">
                    <svg
                        class="
                            hidden
                            lg:block
                            absolute
                            top-0
                            right-0
                            -mt-20
                            -mr-20
                        "
                        width="404"
                        height="384"
                        fill="none"
                        viewBox="0 0 404 384"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                                patternUnits="userSpaceOnUse"
                            >
                                <rect
                                    x="0"
                                    y="0"
                                    width="4"
                                    height="4"
                                    class="text-red-200"
                                    fill="currentColor"
                                />
                            </pattern>
                        </defs>
                        <rect
                            width="404"
                            height="384"
                            fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
                        />
                    </svg>
                    <div
                        class="
                            relative
                            text-base
                            mx-auto
                            max-w-prose
                            lg:max-w-none
                        "
                    >
                        <figure>
                            <div class="aspect-w-12 aspect-h-7 lg:aspect-none">
                                <img
                                    class="
                                        rounded-lg
                                        shadow-lg
                                        object-cover object-center
                                    "
                                    src="@/assets/pup6.jpg"
                                    alt="Whitney leaning against a railing on a downtown street"
                                    width="1184"
                                    height="1376"
                                />
                            </div>
                            <figcaption class="mt-3 flex text-sm text-red-500">
                                <CameraIcon
                                    class="flex-none w-5 h-5 text-red-400"
                                    aria-hidden="true"
                                />
                                <span class="ml-2"
                                    >Annick Rhéaume Watters
                                </span>
                            </figcaption>
                        </figure>
                    </div>
                </div>
                <div class="mt-8 lg:mt-0">
                    <div
                        class="
                            mt-5
                            prose prose-red
                            text-gray-500
                            mx-auto
                            lg:max-w-none lg:row-start-1 lg:col-start-1
                        "
                    >
                        <p>
                            Notre honnêteté et notre amour envers nos toutous
                            vous garantissent une belle histoire d'amour avec
                            ces magnifiques bêtes. Nous sommes là pour répondre
                            à vos questions et votre satisfaction est la nôtre!
                        </p>
                        <ul role="list">
                            <li>
                                Expérience avec les Frenchies depuis 10 ans.
                            </li>
                            <li>
                                On vous mets en confiance en repondant à tout
                                vos questions, les bouledogues, on connait ça!
                            </li>
                            <li>
                                Nos chiots sont socialisés dès leur jeunes âges,
                                avec d'autres chiens et aussi des enfants!
                            </li>
                        </ul>
                    </div>
                    <button
                        type="button"
                        class="
                            my-8
                            inline-flex
                            items-center
                            px-6
                            py-3
                            border border-transparent
                            text-base
                            font-medium
                            rounded-md
                            shadow-sm
                            text-white
                            bg-red-600
                            hover:bg-red-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-red-500
                        "
                        @click="$router.push({ name: 'Contact' })"
                    >
                        Contactez-nous
                    </button>

                    <faqs-vue />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { CameraIcon } from '@heroicons/vue/solid';
    import FaqsVue from '@/components/Faqs.vue';
    import ScrollBehavior from '@/behaviors/Scroll';

    export default {
        components: {
            FaqsVue,
            CameraIcon,
        },
        mounted: () => {
            ScrollBehavior.toTop();
        },
    };
</script>
