
    import { CameraIcon } from '@heroicons/vue/solid';
    import FaqsVue from '@/components/Faqs.vue';
    import ScrollBehavior from '@/behaviors/Scroll';

    export default {
        components: {
            FaqsVue,
            CameraIcon,
        },
        mounted: () => {
            ScrollBehavior.toTop();
        },
    };
